import React, { useEffect, useState } from 'react'

import SEO from '../blocks/layout/seo'
import AppContainer from '../blocks/layout/appContainer'
import { Button, Fieldset, Table } from '@geist-ui/core'
import axios from 'axios'
import { Firebase } from '../config/firebase'
import moment from 'moment'

const Billing = () => {
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [userInvoices, setUserInvoices] = useState([])

  async function fetchMyAPI(user) {
    let sub = await axios.get(
      `${process.env.GATSBY_API_BASE_URL}/subscriptions/${user.uid}`,
    )
    let inv = await axios.get(
      `${process.env.GATSBY_API_BASE_URL}/customerInvoices/${user.uid}`,
    )
    if (sub.data.message === undefined) {
      setUserSubscriptions(sub.data.data)
    }
    if (inv.data.message === undefined) {
      setUserInvoices(inv.data.data)
    }
  }

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(fetchMyAPI)

    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  const renderAddedDate = (value, rowData, rowIndex) => {
    return moment.unix(value).fromNow()
  }

  const renderAction = (value, rowData, index) => {
    const removeHandler = () => {
      var data = JSON.stringify({
        customer_id: rowData.customer,
      })

      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/manageSubscription`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          const { url } = response.data
          window.open(url, '_blank').focus()
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    return (
      <Button
        type="secondary"
        auto
        scale={1 / 3}
        font="12px"
        onClick={removeHandler}
      >
        Manage this subscription
      </Button>
    )
  }

  return (
    <>
      <SEO title="Billing Page — Quick API" />
      <AppContainer menuKey="5">
        <br />
        <div className="settings-fieldset">
          {userSubscriptions.length > 0 ? (
            <>
              <Fieldset style={{ border: 0 }}>
                <Fieldset.Title>Subscription</Fieldset.Title>
                <Fieldset.Subtitle>
                  Your active subscriptions list.
                </Fieldset.Subtitle>
                <Fieldset.Subtitle>
                  Want's to do any changes with current plan? Like{' '}
                  <b>Update plan</b>, <b>Update payment method</b>,{' '}
                  <b>Billing information</b>, or <b>cancel</b> current
                  subscription. Then just Click on{' '}
                  <b>Manage this Subscription</b> button.
                </Fieldset.Subtitle>
              </Fieldset>

              <Table data={userSubscriptions}>
                {/* <Table.Column prop="planId" label="Plan" /> */}
                {/* <Table.Column
            prop="planInterval"
            label="Subscription Cycle"
      /> */}
                <Table.Column prop="id" label="Subscription Id" />
                <Table.Column
                  prop="created"
                  label="created"
                  render={renderAddedDate}
                />
                <Table.Column prop="status" label="status" />
                <Table.Column
                  label="Manage"
                  width={150}
                  render={renderAction}
                />
              </Table>
            </>
          ) : (
            <Fieldset style={{ border: 0 }}>
              <Fieldset.Title>Subscription</Fieldset.Title>
              <Fieldset.Subtitle>
                Your active subscription is Plan.
              </Fieldset.Subtitle>
              <Fieldset.Subtitle>
                Want's to do any changes with current plan? Like Update plan,
                Update payment method, Billing information, or cancel current
                subscription.
              </Fieldset.Subtitle>
            </Fieldset>
          )}
          <br />
          <br />

          <Fieldset style={{ border: 0 }}>
            <Fieldset.Title>Invoices</Fieldset.Title>
          </Fieldset>

          <br />
          <Table data={userInvoices}>
            <Table.Column prop="id" label="Inovice Id" />
            <Table.Column
              prop="amount_paid"
              label="Invoice Amount"
              render={(value, rowData, index) => {
                return `$${value / 100}`
              }}
            />
            <Table.Column prop="customer_email" label="Invoice Email" />
            <Table.Column prop="status" label="status" />
            <Table.Column
              prop="invoice_pdf"
              label="View Invoice PDF"
              render={(value, rowData, index) => {
                return (
                  <a href={value} target="_blank">
                    Download PDF
                  </a>
                )
              }}
            />
          </Table>
        </div>
      </AppContainer>
    </>
  )
}

export default Billing
